@use '../../styles/mixins' as mixins;

.table-block {

	@include mixins.sectionStyles;

	.bnpp-container {
		overflow: auto;
	}

	table {

		min-width: 100%;

		:is(th, td) {
			padding: var(--wp--preset--spacing--40);
			min-width: 120px;
	
			sub {
				bottom: 0.25em;
			}

			a {
				text-decoration: underline;
			}
		}
	
		th {
			color: var(--wp--preset--color--bnpp-green);
			background-color: var(--wp--preset--color--bnpp-green-tint-15);
			font-weight: 400;
		}
	
		td {
			text-align: center;
		}

	}

}